import React from 'react';
import PropTypes from 'prop-types';
import styles from './infoPage.module.css';

function InfoPage({ headline, message, Icon, pageName }) {
  const _headline = {
    __html: headline,
  };

  // Conditionally choose class names based on the is404 prop
  const pageClass = pageName
    ? `${styles.infoPage} ${styles.infoPage404}`
    : styles.infoPage;
  const messageClass = pageName
    ? `${styles.infoPage__message} ${styles.infoPage404__message}`
    : styles.infoPage__message;
  const headlineClass = pageName
    ? `${styles.infoPage__headline} ${styles.infoPage404__headline}`
    : styles.infoPage__headline;
  const contentClass = pageName
    ? `${styles.infoPage__content} ${styles.infoPage404__content}`
    : styles.infoPage__content;

  return (
    <div className={pageClass}>
      <div className={messageClass}>{Icon}</div>
      <div className={contentClass}>
        <div className={headlineClass} dangerouslySetInnerHTML={_headline} />
        <div className={messageClass}>{message}</div>
      </div>
    </div>
  );
}

InfoPage.propTypes = {
  headline: PropTypes.string,
  Icon: PropTypes.node,
  message: PropTypes.string,
  pageName: PropTypes.string,
};

export default InfoPage;
