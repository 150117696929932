import InfoPage from 'components/site-messaging/InfoPage';
import React from 'react';

export default function NotFound() {
  return <InfoPage headline="404" message="Not Found" pageName="404" />;
}

export function getStaticProps() {
  return {
    props: {},
  };
}
